import {
    Environment,
    ENVIRONMENT_TOKEN,
    ResponseStatus,
    ServerResponseInterface,
    StoreWrapperInterface,
    STORE_WRAPPER_TOKEN,
    UserInterface,
} from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, Select } from '@ngxs/store';
import { Observable, of, from, throwError, merge, combineLatest } from 'rxjs';
import { tap, catchError, take, switchMap, throttleTime, finalize, map, withLatestFrom } from 'rxjs/operators';
import { ChangeJobStatus } from '../+state/app-state/actions/change-job-status';
import { ClearAwaitingJobStatus } from '../+state/app-state/actions/clear-awaiting-job-status';
import { ClearJobsLoadingStatus } from '../+state/app-state/actions/clear-jobs-loading-status';
import { LoadJobsEvent } from '../+state/app-state/actions/load-jobs-event';
import { SetAwaitingJobStatus } from '../+state/app-state/actions/set-awaiting-job-status';
import { SetJobUserViewStatus } from '../+state/app-state/actions/set-job-user-view-status';
import { SetJobsLoadingStatus } from '../+state/app-state/actions/set-jobs-loading-status';
import { UpdateJobs } from '../+state/app-state/actions/update-jobs';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { MODULE_ROOT_PATH } from '../constants/routing.constants';
import { JOBS_LOADING_THROTTLING_TIMEOUT } from '../constants/timer.constants';
import { JobDTOInterface } from '../dto/job.dto.interface';
import { JobStatus } from '../enums/job-status.enum';
import { jobFromDto } from '../helpers/mapper.helper';
import { JobChangeStatusInterface } from '../interfaces/job-change-status.interface';
import { JobInterface } from '../interfaces/job.interface';
import { ShiftStatus } from '../interfaces/shift-status.interface';
import { JobChangeDictionaryService } from './job-change-dictionary.service';

const CONFIG_KEY = 'JOB';

@Injectable({
    providedIn: 'root',
})
export class JobsService {
    @Select(JobsPlacementsState.job$) private job$: Observable<JobInterface>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly alertController: AlertController,
        private readonly http: HttpClient,
        private readonly navigationController: NavController,
        private readonly jobChangeDictionaryService: JobChangeDictionaryService,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void | ServerResponseInterface<Array<JobDTOInterface>> | ServerResponseInterface<undefined>> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadJobsEvent),
                    throttleTime(JOBS_LOADING_THROTTLING_TIMEOUT),
                    switchMap(() => this.load()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(ChangeJobStatus),
                    switchMap(({ id, status, shiftsStatuses }: ChangeJobStatus) => this.changeStatus(id, status, shiftsStatuses)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetJobUserViewStatus),
                    switchMap(({ jobId }: SetJobUserViewStatus) => this.setJobUserViewStatus(jobId)),
                ),
        );
    }

    public changeStatus(id: number, status: JobStatus, shiftsStatuses?: Array<ShiftStatus>): Observable<void> {
        this.storeWrapper.loadingStart();

        return this.storeWrapper.isNetworkConnected$
            .pipe(
                take(1),
                switchMap((isConnected: boolean) => {
                    const data: JobChangeStatusInterface = {
                        jobID: id,
                        jobStatus: status,
                        shiftsStatuses,
                    };

                    return isConnected
                        ? this.setStatusOnline$(data)
                        : this.setStatusOffline$(data);
                }),
                catchError(error => {
                    console.log(error);

                    return of([]);
                }),
                finalize(() => this.storeWrapper.loadingEnd()),
            );
    }

    public sendJobStatusToServer$(data: JobChangeStatusInterface): Observable<any> {
        const url = `${this.environment.apiURL}/job/status`;

        return this.http.patch<ServerResponseInterface<undefined>>(url, data)
            .pipe(
                take(1),
                tap(() => this.clearAwaitingJobStatus(data)),
            );
    }

    public load(): Observable<ServerResponseInterface<Array<JobDTOInterface>>> {
        this.setJobsLoadingStatus();

        const url = `${this.environment.apiURL}/jobs`;

        return this.http.get<ServerResponseInterface<Array<JobDTOInterface>>>(url)
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<Array<JobDTOInterface>>) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === ResponseStatus.OK) {
                        this.updateJobs(response.data.map(jobFromDto));

                        return;
                    }
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return throwError(() => new Error(error));
                }),
                finalize(() => this.clearJobsLoadingStatus()),
            );
    }

    private setStatusOnline$(data: JobChangeStatusInterface): Observable<any> {
        const url = `${this.environment.apiURL}/job/status`;

        return this.http.patch<ServerResponseInterface<undefined>>(url, data)
            .pipe(
                withLatestFrom(this.getChatStartConfigStatus$()),
                take(1),
                tap(([response, showChatWhenInterestedInSomeShiftsOfJob]: [ServerResponseInterface<undefined>, boolean]) => {
                    if (response.status === ResponseStatus.OK) {
                        this.changeStatusOnlineHandler(data, showChatWhenInterestedInSomeShiftsOfJob);

                        return;
                    }

                    this.storeWrapper.showAlert(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showAlert(error.message);

                    return of();
                }),
            );
    }

    private setStatusOffline$(data: JobChangeStatusInterface): Observable<any> {
        return from(this.alertController.create({
            header: 'Offline mode',
            message: `There is no access to the Internet.
                When you are back online, just open this app to send the information to the office`,
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (): void => {
                        this.setAwaitingJobStatus(data);
                        this.changeStatusOfflineHandler(data);
                    },
                },
            ],
        })
            .then(alert => alert.present()));
    }

    private changeStatusOnlineHandler(
        data: JobChangeStatusInterface,
        showChatWhenInterestedInSomeShiftsOfJob: boolean,
    ): void {
        if (
            data.jobStatus === JobStatus.INTERESTED_SOME_SHIFTS &&
            showChatWhenInterestedInSomeShiftsOfJob
        ) {
            combineLatest([
                this.storeWrapper.user$,
                this.job$,
            ]).pipe(
                take(1),
                tap(([{ consultantID }, job]: [UserInterface, JobInterface]) => {
                    if (consultantID) {
                        const chatMessage = `
                            I am interested in some shifts in this job: ${job.jobData?.jobTitle},
                            id: ${job.id}, reference: ${job.jobData?.jobRefNo}`;

                        this.storeWrapper.joinConsultant(`${consultantID}`, 'consultant', chatMessage);
                    }
                }),
            ).subscribe();

            return;
        }

        this.storeWrapper.loadingEnd();

        const config = this.jobChangeDictionaryService.get()[data.jobStatus];

        if (!config) {
            return;
        }

        const { message, url } = config;

        this.storeWrapper.showAlert(message);
        this.gotoSection(url);
    }

    private changeStatusOfflineHandler(
        data: JobChangeStatusInterface,
    ): void {
        const config = this.jobChangeDictionaryService.get()[data.jobStatus];

        if (!config) {
            return;
        }

        const { url } = config;

        this.storeWrapper.loadingEnd();
        this.gotoSection(url);
    }

    private gotoSection(url: string): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot([baseUrl, MODULE_ROOT_PATH, url]);
            });
    }

    private setJobUserViewStatus(jobID: number): Observable<ServerResponseInterface<undefined>> {
        const url = `${this.environment.apiURL}/job/view-status`;

        return this.http.patch<ServerResponseInterface<undefined>>(url, { jobID })
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<undefined>) => {
                    if (response.status === ResponseStatus.OK) {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of();
                }),
            );
    }

    private getChatStartConfigStatus$(): Observable<boolean> {
        const parameterKey = 'showChatWhenInterestedInSomeShiftsOfJob';

        return this.storeWrapper.getMenuItemProperty$<boolean>(CONFIG_KEY, parameterKey);
    }

    @Dispatch()
    private updateJobs(data: Array<JobInterface>): UpdateJobs {
        return new UpdateJobs(data);
    }

    @Dispatch()
    private setAwaitingJobStatus(data: JobChangeStatusInterface): SetAwaitingJobStatus {
        return new SetAwaitingJobStatus(data);
    }

    @Dispatch()
    private clearAwaitingJobStatus(data: JobChangeStatusInterface): ClearAwaitingJobStatus {
        return new ClearAwaitingJobStatus(data);
    }

    @Dispatch()
    private setJobsLoadingStatus(): SetJobsLoadingStatus {
        return new SetJobsLoadingStatus();
    }

    @Dispatch()
    private clearJobsLoadingStatus(): ClearJobsLoadingStatus {
        return new ClearJobsLoadingStatus();
    }
}

import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SavedFiltersEffectsService } from './services/saved-filters.effects.service';
import { TimeSheetService } from './services/time-sheet.service';

@Component({
    selector: 'jobs-placements-jobs-placements',
    templateUrl: 'jobs-placements.component.html',
    styleUrls: ['jobs-placements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsPlacementsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(
        private readonly timeSheetService: TimeSheetService,
        private readonly savedFiltersActionsService: SavedFiltersEffectsService,
    ) { }

    public ngOnInit(): void {
        const subscription = this.timeSheetService.init$().subscribe();

        this.subscriptions.add(subscription);

        const subs = this.savedFiltersActionsService.init$().subscribe();

        this.subscriptions.add(subs);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

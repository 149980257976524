import { Environment, ENVIRONMENT_TOKEN, ServerResponseInterface, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { merge, Observable, throwError, of } from 'rxjs';
import { take, tap, catchError, finalize, switchMap } from 'rxjs/operators';
import { ChangeCollectionStatus } from '../+state/app-state/actions/change-collection-status';
import { ClearCollectionsLoadingStatus } from '../+state/app-state/actions/clear-collections-loading-status';
import { LoadCollections } from '../+state/app-state/actions/load-collections';
import { LoadCollectionsSuccess } from '../+state/app-state/actions/load-collections-success';
import { SetCollectionUserViewStatus } from '../+state/app-state/actions/set-collection-user-view-status';
import { SetCollectionsLoadingStatus } from '../+state/app-state/actions/set-collections-loading-status';
import { MODULE_ROOT_PATH } from '../constants/routing.constants';
import { CollectionDto } from '../dto/collection.dto';
import { JobStatus } from '../enums/job-status.enum';
import { CollectionInterface } from '../interfaces/collection.interface';
import { ShiftStatus } from '../interfaces/shift-status.interface';
import { JobChangeDictionaryService } from './job-change-dictionary.service';

@Injectable({
    providedIn: 'root',
})
export class CollectionsService {

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly navigationController: NavController,
        private readonly jobChangeDictionaryService: JobChangeDictionaryService,
    ) {
        this.init$().subscribe();
    }

    public init$(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadCollections),
                    switchMap(() => this.load$()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(ChangeCollectionStatus),
                    switchMap(({ id, status, shiftsStatuses }: ChangeCollectionStatus) => this.changeStatus(id, status, shiftsStatuses)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetCollectionUserViewStatus),
                    switchMap(({ collectionId }: SetCollectionUserViewStatus) => this.setCollectionUserViewStatus(collectionId)),
                ),
        );

    }

    public load$(): Observable<ServerResponseInterface<Array<CollectionDto>>> {
        this.setCollectionsLoadingStatus();

        return this.http.get<ServerResponseInterface<Array<CollectionDto>>>(`${this.environment.apiURL}/collections`)
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<Array<CollectionDto>>) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === 'ok') {
                        this.loadCollectionsSuccess(response.data);

                        return;
                    }
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return throwError(() => new Error(error));
                }),
                finalize(() => this.clearCollectionsLoadingStatus()),
            );
    }

    private setCollectionUserViewStatus(collectionId: number): Observable<ServerResponseInterface<undefined>> {
        return this.http.patch<ServerResponseInterface<undefined>>(`${this.environment.apiURL}/collections/view-status`, {
            collectionId,
        })
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<undefined>) => {
                    if (response.status === 'ok') {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of();
                }),
            );
    }

    public changeStatus(id: number, status: JobStatus, shiftsStatuses: Array<ShiftStatus>): Observable<unknown> {
        this.storeWrapper.loadingStart();

        const data = {
            collectionId: id,
            status,
            shiftsStatuses,
        };

        return this.http.patch<ServerResponseInterface<undefined>>(`${this.environment.apiURL}/collections/status`, data)
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<undefined>) => {
                    if (response.status === 'ok') {
                        this.changeStatusHandler(status);

                        return;
                    }

                    this.storeWrapper.showAlert(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showAlert(error.message);

                    return of();
                }),
                finalize(() => this.storeWrapper.loadingEnd()),
            );
    }

    private changeStatusHandler(status: JobStatus): void {

        const config = this.jobChangeDictionaryService.get()[status];

        if (!config) {
            return;
        }

        const { url, message } = config;

        this.storeWrapper.showAlert(message);
        this.gotoSection(url);
    }

    private gotoSection(url: string): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot([baseUrl, MODULE_ROOT_PATH, url]);
            });
    }

    @Dispatch()
    private setCollectionsLoadingStatus(): SetCollectionsLoadingStatus {
        return new SetCollectionsLoadingStatus();
    }

    @Dispatch()
    private clearCollectionsLoadingStatus(): ClearCollectionsLoadingStatus {
        return new ClearCollectionsLoadingStatus();
    }

    @Dispatch()
    private loadCollectionsSuccess(data: Array<CollectionInterface>): LoadCollectionsSuccess {
        return new LoadCollectionsSuccess(data);
    }
}

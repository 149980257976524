import { STORE_WRAPPER_TOKEN, ENVIRONMENT_TOKEN, Environment, ServerResponseInterface } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Actions, ofActionDispatched } from '@ngxs/store';
import { Observable, merge, of, throwError, from } from 'rxjs';
import { throttleTime, switchMap, tap, catchError, finalize, take, map } from 'rxjs/operators';
import { ChangeShiftStatus } from '../+state/app-state/actions/change-shift-status';
import { ClearAwaitingShiftStatus } from '../+state/app-state/actions/clear-awaiting-shift-status';
import { ClearShiftsLoadingStatus } from '../+state/app-state/actions/clear-shifts-loading-status';
import { LoadShiftsEvent } from '../+state/app-state/actions/load-shifts-event';
import { SetAwaitingShiftStatus } from '../+state/app-state/actions/set-awaiting-shift-status';
import { SetShiftUserViewStatus } from '../+state/app-state/actions/set-shift-user-view-status';
import { SetShiftsLoadingStatus } from '../+state/app-state/actions/set-shifts-loading-status';
import { UpdateShifts } from '../+state/app-state/actions/update-shifts';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { MODULE_ROOT_PATH } from '../constants/routing.constants';
import { JOBS_LOADING_THROTTLING_TIMEOUT } from '../constants/timer.constants';
import { ShiftDTOInterface } from '../dto/shift.dto.interface';
import { JobStatus } from '../enums/job-status.enum';
import { shiftFromDto } from '../helpers/mapper.helper';
import { ShiftChangeStatusInterface } from '../interfaces/shift-change-status.interface';
import { ShiftInterface } from '../interfaces/shift.interface';
import { JobChangeDictionaryService } from './job-change-dictionary.service';

@Injectable({
    providedIn: 'root',
})
export class ShiftsService {
    @Select(JobsPlacementsState.shift$) private shift$: Observable<ShiftInterface>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly alertController: AlertController,
        private readonly http: HttpClient,
        private readonly navigationController: NavController,
        private readonly jobChangeDictionaryService: JobChangeDictionaryService,
    ) {
        this.init().subscribe();
    }

    public init(): Observable<void | ServerResponseInterface<Array<ShiftDTOInterface>>> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadShiftsEvent),
                    throttleTime(JOBS_LOADING_THROTTLING_TIMEOUT),
                    switchMap(() => this.load()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(ChangeShiftStatus),
                    switchMap(({ id, status }: ChangeShiftStatus) => this.changeStatus(id, status)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SetShiftUserViewStatus),
                    switchMap(({ shiftId }: SetShiftUserViewStatus) => this.setShiftUserViewStatus(shiftId)),
                ),
        );
    }

    public changeStatus(id: number, status: JobStatus): Observable<void> {
        this.storeWrapper.loadingStart();

        return this.storeWrapper.isNetworkConnected$.pipe(
            take(1),
            switchMap((isConnected: boolean) => {
                const data: ShiftChangeStatusInterface = {
                    shiftId: id,
                    jobStatus: status,
                };

                return isConnected ? this.setStatusOnline$(data) : this.setStatusOffline$(data);
            }),
            catchError(error => {
                console.log(error);

                return of([]);
            }),
            finalize(() => this.storeWrapper.loadingEnd()),
        );
    }

    public sendShiftStatusToServer$(data: ShiftChangeStatusInterface): Observable<any> {
        return this.http.patch<ServerResponseInterface<undefined>>(`${this.environment.apiURL}/shifts/status`, data)
            .pipe(
                take(1),
                tap(() => this.clearAwaitingShiftStatus(data)),
            );
    }

    public load(): Observable<ServerResponseInterface<Array<ShiftDTOInterface>>> {
        this.setShiftsLoadingStatus();

        return this.http.get<ServerResponseInterface<Array<ShiftDTOInterface>>>(`${this.environment.apiURL}/shifts`)
            .pipe(
                take(1),
                tap((response: ServerResponseInterface<Array<ShiftDTOInterface>>) => {
                    this.storeWrapper.showToast(response.message);

                    if (response.status === 'ok') {
                        this.updateShifts(response.data.map(shiftFromDto));

                        return;
                    }
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return throwError(error);
                }),
                finalize(() => this.clearShiftsLoadingStatus()),
            );
    }

    private setStatusOnline$(data: ShiftChangeStatusInterface): Observable<any> {
        return this.http.patch<ServerResponseInterface<undefined>>(`${this.environment.apiURL}/shifts/status`, data)
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        this.changeStatusHandler(data, true);

                        return;
                    }

                    this.storeWrapper.showAlert(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showAlert(error.message);

                    return of();
                }),
            );
    }

    private setStatusOffline$(data: ShiftChangeStatusInterface): Observable<any> {
        return from(this.alertController.create({
            header: 'Offline mode',
            message: `There is no access to the Internet.
              When you are back online, just open this app to send the information to the office`,
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: (): void => {
                        this.setAwaitingShiftStatus(data);
                        this.changeStatusHandler(data, false);
                    },
                },
            ],
        })
            .then(alert => alert.present()));
    }

    private changeStatusHandler(data: ShiftChangeStatusInterface, isNetworkConnected: boolean): void {
        if (data.jobStatus === JobStatus.INTERESTED_SOME_SHIFTS) {
            if (!this.storeWrapper.user.consultantID || !isNetworkConnected) {
                return;
            }

            this.shift$
                .pipe(
                    map((shift: ShiftInterface) => `I am interested in some shifts in this job: ${shift.jobData?.jobTitle}, id: ${shift.id}, reference: ${shift.jobData?.jobRefNo}`),
                    tap((chatMessage: string) => this.storeWrapper.joinConsultant(`${this.storeWrapper.user.consultantID}`, 'consultant', chatMessage)),
                    take(1),
                )
                .subscribe();

            return;
        }

        this.storeWrapper.loadingEnd();

        const config = this.jobChangeDictionaryService.get()[data.jobStatus];

        if (!config) {
            return;
        }

        const { message, url } = config;

        if (isNetworkConnected) {
            this.storeWrapper.showAlert(message);
        }

        this.gotoSection(url);
    }

    private gotoSection(url: string): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot([baseUrl, MODULE_ROOT_PATH, url]);
            });
    }

    private setShiftUserViewStatus(shiftId: number): Observable<void> {
        return this.http.patch<ServerResponseInterface<undefined>>(`${this.environment.apiURL}/shifts/view-status`, {
            shiftId,
        })
            .pipe(
                take(1),
                tap((response: any) => {
                    if (response.status === 'ok') {
                        return;
                    }

                    this.storeWrapper.showToast(response.message);
                }),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return of([]);
                }),
            );
    }

    @Dispatch()
    private updateShifts(data: Array<ShiftInterface>): UpdateShifts {
        return new UpdateShifts(data);
    }

    @Dispatch()
    private setAwaitingShiftStatus(data: ShiftChangeStatusInterface): SetAwaitingShiftStatus {
        return new SetAwaitingShiftStatus(data);
    }

    @Dispatch()
    private clearAwaitingShiftStatus(data: ShiftChangeStatusInterface): ClearAwaitingShiftStatus {
        return new ClearAwaitingShiftStatus(data);
    }

    @Dispatch()
    private setShiftsLoadingStatus(): SetShiftsLoadingStatus {
        return new SetShiftsLoadingStatus();
    }

    @Dispatch()
    private clearShiftsLoadingStatus(): ClearShiftsLoadingStatus {
        return new ClearShiftsLoadingStatus();
    }
}

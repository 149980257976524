// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../../../../package.json';

export const environment = {
    production: false,
    apiURL: 'https://mcadev.actassa.com/mobile-dev',
    botURL: 'wss://mcadev.actassa.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyC3nRoMKio0aKVP1JLwmuTDv0CLxoc2FKQ',
        authDomain: 'mcarthur-hr.firebaseapp.com',
        projectId: 'mcarthur-hr',
        storageBucket: 'mcarthur-hr.appspot.com',
        messagingSenderId: '673053088516',
        appId: '1:673053088516:web:c4a8303bc80115e222ce51',
    },
    socketIoConfig: {
        url: 'https://mcadev.actassa.com:3006',
        options: {
            autoConnect: true,
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.mcarthur',
    appleAppId: '1543990033',
    appName: 'McArthur',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { parseDateAsDateInTimezone, InformErrorService } from '@actassa/shared';
import { WeekDay } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { formatISO9075, isSameDay, isSameWeek, startOfDay } from 'date-fns';
import { first, isArray, isEqual, sortBy } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, withLatestFrom, shareReplay, filter, switchMap } from 'rxjs/operators';
import { PickTimesheet } from '../+state/app-state/actions/pick-timesheet';
import { JobsPlacementsState } from '../+state/app-state/app.state';
import { TIMESHEET_CREATION_ERROR } from '../constants/timesheet.constants';
import { PlacementOvertime } from '../dto/placement.dto.interface';
import { SectionsEnum } from '../enums/sections.enum';
import { formatRecordedHours } from '../helpers/format-record-hours.helper';
import { PlacementInterface } from '../interfaces/placement.interface';
import { RecordTimeConfig } from '../interfaces/record-time-config.interface';
import { TimesheetHourInterface, TimesheetInterface } from '../interfaces/timesheet.interface';
import { buildNewTimesheet } from '../pages/record-time/build-new-timesheet.helper';
import { TimeSheetService } from './time-sheet.service';

const TIMESHEET_SORT_KEY = 'TimesheetID';
const PAGE_KEY = SectionsEnum.PLACEMENT;
const CONFIG_PARAMETER_KEY = 'recordTime';

@Injectable()
export class TimeSheetFacadeService {
    @Select(JobsPlacementsState.day$) private _day$: Observable<string>;
    @Select(JobsPlacementsState.placement$) private _placement$: Observable<PlacementInterface>;
    @Select(JobsPlacementsState.placementOvertimes$) private placementOvertimes$: Observable<Array<PlacementOvertime>>;
    @Select(JobsPlacementsState.timesheet$) private timesheet$: Observable<TimesheetInterface | null>;
    @Select(JobsPlacementsState.week$) private _week$: Observable<string>;

    public placement$: Observable<PlacementInterface>;
    public isClosedTimesheet$: Observable<boolean>;
    public recordTimeConfig$: Observable<RecordTimeConfig>;

    private lockedTimesheetStatuses$: Observable<Array<string>>;
    private readonly loadTimesheetsActivator$ = new Subject();
    private readonly createRequestMap = new Map<string, boolean>();
    private readonly day$: Observable<Date>;
    private readonly week$: Observable<Date>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly timeSheetService: TimeSheetService,
        private readonly store: Store,
        private readonly informErrorService: InformErrorService,
    ) {
        this.placement$ = this._placement$.pipe(distinctUntilChanged(isEqual), shareReplay(1));
        this.day$ = this._day$.pipe(
            distinctUntilChanged(),
            withLatestFrom(this.storeWrapper.timezone$),
            map(([day, timezone]) => parseDateAsDateInTimezone(day, timezone)),
        );
        this.week$ = this._week$.pipe(
            distinctUntilChanged(),
            withLatestFrom(this.storeWrapper.timezone$),
            map(([week, timezone]) => parseDateAsDateInTimezone(week, timezone)),
        );

        this.initRecordTimeConfig$();
        this.initLockedTimesheetStatuses();
        this.initClosedTimesheetCheck();
    }

    public getTotalRecordedHours$(): Observable<Array<string>> {
        return this.timesheet$
            .pipe(
                withLatestFrom(this.placementOvertimes$),
                map(([timesheet, placementOvertimes]: [TimesheetInterface | null, Array<PlacementOvertime>]) => {
                    if (!isArray(timesheet?.timeSheetHours)) {
                        return placementOvertimes.map(({ overtimeValueName }) => `${overtimeValueName}: `);
                    }

                    return placementOvertimes.map(({ overtimeValueName, placementOvertimeId }) => {
                        const countRecordedHours = timesheet.timeSheetHours
                            .reduce((accumulator, timesheetHour) => {
                                if (timesheetHour?.PLACEMENTOVERTIMEID === placementOvertimeId) {
                                    return accumulator + timesheetHour.HOURSWORKED || 0;
                                }

                                return accumulator;
                            }, 0);

                        return `${overtimeValueName}: ${formatRecordedHours(countRecordedHours * 60)}`;
                    });
                }),
            );
    }

    public getDayTotalRecordedHours$(): Observable<Array<string>> {
        return this.timesheet$
            .pipe(
                withLatestFrom(this.placementOvertimes$, this.day$, this.storeWrapper.timezone$),
                map(([timesheet, placementOvertimes, day, timezone]:
                    [TimesheetInterface | null, Array<PlacementOvertime>, Date, string]) => {
                    if (!isArray(timesheet?.timeSheetHours)) {
                        return placementOvertimes.map(({ overtimeValueName }) => `${overtimeValueName}: `);
                    }

                    return placementOvertimes.map(({ overtimeValueName, placementOvertimeId }) => {
                        const countRecordedHours = timesheet.timeSheetHours
                            .filter((timesheetHour: TimesheetHourInterface) => {
                                const timesheetStart: Date = startOfDay(parseDateAsDateInTimezone(timesheetHour.WORKEDON, timezone));

                                return isSameDay(timesheetStart, day);
                            })
                            .reduce((accumulator, timesheetHour) => {
                                if (timesheetHour?.PLACEMENTOVERTIMEID === placementOvertimeId) {
                                    return accumulator + timesheetHour.HOURSWORKED || 0;
                                }

                                return accumulator;
                            }, 0);

                        return `${overtimeValueName}: ${formatRecordedHours(countRecordedHours * 60)}`;
                    });
                }),
            );
    }

    public initTimesheets$(): Observable<unknown> {
        return this.loadTimesheetsActivator$
            .pipe(
                switchMap(() => this.activateTimesheets$()),
            );
    }

    public activateTimesheets$(): Observable<unknown> {
        return this.placement$
            .pipe(
                filter(Boolean),
                distinctUntilChanged(isEqual),
                switchMap(({ placementId }) => this.loadTimesheets$(placementId)),
            );
    }

    public loadTimesheets$(placementId: number): Observable<unknown> {
        return this.timeSheetService.loadTimesheets$(placementId)
            .pipe(
                switchMap((timesheets: Array<TimesheetInterface>) => this.selectTimesheetStream$(timesheets)),
            );
    }

    public loadTimesheets(): void {
        this.loadTimesheetsActivator$.next(null);
    }

    private selectTimesheetStream$(timesheets: Array<TimesheetInterface>): Observable<unknown> {
        return combineLatest([this.placement$, this.week$])
            .pipe(
                filter(([placement, week]: [PlacementInterface, Date]) => !!placement && !!week),
                withLatestFrom(this.storeWrapper.timezone$),
                distinctUntilChanged(isEqual),
                switchMap(([[placement, weekStart], timezone]:
                    [[PlacementInterface, Date], string]) => {
                    const actualTimeSheets = timesheets
                        .filter(({ PlacementID }: TimesheetInterface) => PlacementID === placement.placementId)
                        .filter(({ PeriodStarting }: TimesheetInterface) => {
                            const timesheetStart = parseDateAsDateInTimezone(PeriodStarting, timezone);

                            return isSameWeek(weekStart, timesheetStart, { weekStartsOn: WeekDay.Monday });
                        });

                    // INFO: Договоренност: у нас 1 timesheet в неделю и он с понедельника по воскресенье
                    const actualTimesheet: TimesheetInterface = first(sortBy(actualTimeSheets, TIMESHEET_SORT_KEY));

                    const keyOfCreateRequest = `${placement.placementId}_${formatISO9075(weekStart)}`;

                    if (!actualTimesheet) {
                        if (this.createRequestMap.has(keyOfCreateRequest)) {
                            this.storeWrapper.showToast(TIMESHEET_CREATION_ERROR);

                            this.informErrorService.handleErrorInformRequest({
                                message: TIMESHEET_CREATION_ERROR,
                                placement,
                                weekStart,
                            });

                            throw new Error(TIMESHEET_CREATION_ERROR);
                        }

                        this.createRequestMap.set(keyOfCreateRequest, true);

                        const dto = buildNewTimesheet(placement, weekStart);

                        return this.timeSheetService.handleTimesheet$(dto)
                            .pipe(switchMap(() => this.loadTimesheets$(placement.placementId)));
                    }

                    // Костыль, так как остается лоадер
                    this.timeSheetService.loadingEnd();

                    return this.store.dispatch(new PickTimesheet(actualTimesheet));
                }),
            );
    }

    private initLockedTimesheetStatuses(): void {
        this.lockedTimesheetStatuses$ = this.recordTimeConfig$
            .pipe(
                distinctUntilChanged(isEqual),
                map((recordTimeConfig: RecordTimeConfig) => {
                    if (isArray(recordTimeConfig?.lockedTimeSheetStatuses)) {
                        return recordTimeConfig.lockedTimeSheetStatuses.filter(Boolean);
                    }

                    return [];
                }),
            );
    }

    private initClosedTimesheetCheck(): void {
        this.isClosedTimesheet$ = this.timesheet$
            .pipe(
                withLatestFrom(this.lockedTimesheetStatuses$),
                map(([timesheet, lockedTimesheetStatuses]: [TimesheetInterface, Array<string>]) =>
                    lockedTimesheetStatuses.includes(timesheet?.TimesheetApprovalStatus)),
                distinctUntilChanged(),
            );
    }

    public initRecordTimeConfig$(): void {
        this.recordTimeConfig$ = this.storeWrapper.getMenuItemProperty$<RecordTimeConfig>(PAGE_KEY, CONFIG_PARAMETER_KEY)
            .pipe(
                distinctUntilChanged(isEqual),
                shareReplay(1),
            );
    }
}

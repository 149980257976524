import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { MainMenuItemInterface } from '@actassa/api';
import { Inject, Injectable, Optional } from '@angular/core';
import { Select } from '@ngxs/store';
import { combineLatest, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { JobsPlacementsState } from '../+state/app-state/app.state';
import { CollectionsService } from '../../public_api';
import { JobStatus } from '../enums/job-status.enum';
import { PlacementStatus } from '../enums/placement-status.enum';
import { SectionsEnum } from '../enums/sections.enum';
import { UserViewStatus } from '../enums/user-view-status.enum';
import { CollectionInterface } from '../interfaces/collection.interface';
import { JobInterface } from '../interfaces/job.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';
import { JobsPlacementsPages } from '../main-menu.config';
import { JOBS_PLACEMENTS_TITLES_TOKEN } from '../tokens/titles.token';

import { JobsService } from './jobs.service';
import { PlacementsService } from './placements.service';
import { PushHandlerService } from './push-handler.service';
import { ReportsService } from './reports.service';
import { SchedulerService } from './scheduler.service';
import { ShiftsService } from './shifts.service';

type OfferInterface = JobInterface | ShiftInterface;

const DEFAULT_ITEM_LABEL = 'items';
const ITEM_LABEL_KEY = 'subTitleItemLabel';

@Injectable()
export class MenuItemsService {

    @Select(JobsPlacementsState.collections$) private collections$: Observable<Array<CollectionInterface>>;
    @Select(JobsPlacementsState.jobs$) private jobs$: Observable<Array<JobInterface>>;
    @Select(JobsPlacementsState.shifts$) private shifts$: Observable<Array<ShiftInterface>>;
    @Select(JobsPlacementsState.placements$) private placements$: Observable<Array<PlacementInterface>>;

    private mainMenuItems$: Observable<Array<MainMenuItemInterface>>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        @Optional() @Inject(JOBS_PLACEMENTS_TITLES_TOKEN) private titlesConfig: Record<SectionsEnum, string>,
        private readonly collectionsService: CollectionsService,
        private readonly jobsService: JobsService,
        private readonly shiftsService: ShiftsService,
        private readonly placementsService: PlacementsService,
        private readonly pushHandlerService: PushHandlerService,
        private readonly reportsService: ReportsService,
        private readonly schedulerService: SchedulerService,
    ) {
        this.mainMenuItems$ = of(JobsPlacementsPages(this.titlesConfig || {}));
    }

    public get(mainMenuItems$ = this.mainMenuItems$): Observable<Array<MainMenuItemInterface>> {
        return mainMenuItems$
            .pipe(
                map((items) => this.mapSubTitles(items)),
            );
    }

    private mapSubTitles(mainMenuItems: Array<MainMenuItemInterface>): Array<MainMenuItemInterface> {
        return mainMenuItems.map((mainMenuItem) => {
            switch (mainMenuItem.key) {
                case SectionsEnum.APPLICATIONS:
                    mainMenuItem.subTitle$ = this.initCurrentJobCount$(SectionsEnum.APPLICATIONS);
                    break;

                case SectionsEnum.OPPORTUNITIES:
                    mainMenuItem.subTitle$ = this.initJobOpportunitiesCount$(SectionsEnum.OPPORTUNITIES);
                    break;

                case SectionsEnum.ASSIGNMENTS:
                    mainMenuItem.subTitle$ = this.initPlacementAssignmentsCount$(SectionsEnum.ASSIGNMENTS);
                    break;
            }

            return mainMenuItem;
        });
    }

    private initCurrentJobCount$(key: SectionsEnum): Observable<string> {
        return combineLatest([
            this.collections$,
            this.jobs$,
            this.shifts$,
            this.getSubtitleItemLabel$(key),
            this.storeWrapper.providerId$,
        ])
            .pipe(
                map(([collections, jobs, shifts, itemLabel, providerId]:
                    [Array<CollectionInterface>, Array<JobInterface>, Array<ShiftInterface>, string, string]) => {
                    const shiftsCount = [
                        ...collections,
                        ...jobs,
                        ...shifts,
                    ];
                    const filteredItems = shiftsCount
                        .filter((item: OfferInterface) => item.providerID === providerId)
                        .filter((item: OfferInterface) => [
                            JobStatus.CURRENT_APPLICATION,
                            JobStatus.INTERESTED_SOME_SHIFTS,
                        ].includes(item.status)).length;

                    if (filteredItems) {
                        return `${filteredItems} ${itemLabel}`;
                    }

                    return '';
                }),
            );
    }

    private initJobOpportunitiesCount$(key: SectionsEnum): Observable<string> {
        return combineLatest([
            this.collections$,
            this.jobs$,
            this.shifts$,
            this.getSubtitleItemLabel$(key),
            this.storeWrapper.providerId$,
        ])
            .pipe(
                map(([collections, jobs, shifts, itemLabel, providerId]:
                    [Array<CollectionInterface>, Array<JobInterface>, Array<ShiftInterface>, string, string]) => {
                    const items = [
                        ...collections,
                        ...jobs,
                        ...shifts,
                    ];
                    const filteredItems = items
                        .filter((item: OfferInterface) => item.providerID === providerId)
                        .filter((item: OfferInterface) => [JobStatus.OPEN_OPPORTUNITY].includes(item.status));
                    const filteredItemsLength = filteredItems.length;
                    const newItemsLength = filteredItems.filter(
                        (item: OfferInterface) => item.userViewStatus === UserViewStatus.NEW,
                    ).length;
                    const updatedItemsLength = filteredItems.filter(
                        (item: OfferInterface) => item.userViewStatus === UserViewStatus.UPDATED,
                    ).length;

                    if (newItemsLength && updatedItemsLength) {
                        return `${filteredItemsLength} ${itemLabel} (${newItemsLength + updatedItemsLength} new/updated)`;
                    }

                    if (updatedItemsLength) {
                        return `${filteredItemsLength} ${itemLabel} (${updatedItemsLength} updated)`;
                    }

                    if (newItemsLength) {
                        return `${filteredItemsLength} ${itemLabel} (${newItemsLength} new)`;
                    }

                    if (filteredItemsLength) {
                        return `${filteredItemsLength} ${itemLabel}`;
                    }

                    return '';
                }),
            );
    }

    private initPlacementAssignmentsCount$(key: SectionsEnum): Observable<string> {
        return this.placements$
            .pipe(
                withLatestFrom(this.getSubtitleItemLabel$(key), this.storeWrapper.providerId$),
                map(([placements, itemLabel, providerId]: [Array<PlacementInterface>, string, string]) => {
                    /*
                     * NOTE:
                     * Фильтрация производится по статусу (текущий placement) и по наличию следующей смены
                     * Идентично фильтрации в PlacementsWidget
                     */
                    const filteredPlacements = placements?.filter((placement: PlacementInterface) =>
                        placement.providerID === providerId
                        && [PlacementStatus.PLACEMENT_CURRENT].includes(placement.status)
                        && placement.nextShiftDateTimePair);
                    const filteredPlacementsLength = filteredPlacements?.length;
                    const newPlacementsLength = filteredPlacements?.filter(
                        (placement: PlacementInterface) => placement.userViewStatus === UserViewStatus.NEW,
                    ).length;
                    const updatedPlacementsLength = filteredPlacements?.filter(
                        (placement: PlacementInterface) => placement.userViewStatus === UserViewStatus.UPDATED,
                    ).length;

                    if (newPlacementsLength && updatedPlacementsLength) {
                        return `${filteredPlacementsLength} ${itemLabel} (${newPlacementsLength + updatedPlacementsLength} new/updated)`;
                    }

                    if (updatedPlacementsLength) {
                        return `${filteredPlacementsLength} ${itemLabel} (${updatedPlacementsLength} new/updated)`;
                    }

                    if (newPlacementsLength) {
                        return `${filteredPlacementsLength} ${itemLabel} (${newPlacementsLength} new/updated)`;
                    }

                    if (filteredPlacementsLength) {
                        return `${filteredPlacementsLength} ${itemLabel}`;
                    }

                    return '';
                }),
            );
    }

    private getSubtitleItemLabel$(key: SectionsEnum): Observable<string> {
        return this.storeWrapper.getMenuItemProperty$<string>(key, ITEM_LABEL_KEY)
            .pipe(
                map((itemLabel: string | undefined) => itemLabel || DEFAULT_ITEM_LABEL),
            );
    }
}

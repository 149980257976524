import { Environment, ENVIRONMENT_TOKEN, ServerResponseInterface } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SavedFilterInterface } from '../interfaces/saved-filter.interface';

@Injectable()
export class SavedFiltersApiService {
    private endPoint = 'jobs/filters';

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }

    public getSavedFilters$(): Observable<ServerResponseInterface<Array<SavedFilterInterface>>> {
        return this.http.get<ServerResponseInterface<Array<SavedFilterInterface>>>(
            `${this.environment.apiURL}/${this.endPoint}`,
        );
    }

    public saveFilter$(savedFilter: SavedFilterInterface): Observable<ServerResponseInterface<SavedFilterInterface>> {
        return this.http.post<ServerResponseInterface<SavedFilterInterface>>(
            `${this.environment.apiURL}/${this.endPoint}`,
            savedFilter,
        );
    }

    public removeFilter$(filterId: number): Observable<ServerResponseInterface<unknown>> {
        return this.http.delete<ServerResponseInterface<unknown>>(`${this.environment.apiURL}/${this.endPoint}/${filterId}`);
    }
}

import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched, ofActionSuccessful } from '@ngxs/store';
import { merge, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { ClearJobsFiltersLoadingStatus } from '../+state/app-state/actions/clear-jobs-filters-loading-status';
import { LoadFiltersFailureAction } from '../+state/app-state/actions/load-filters-failure.action';
import { LoadFiltersSuccessAction } from '../+state/app-state/actions/load-filters-success.action';
import { LoadFiltersAction } from '../+state/app-state/actions/load-filters.action';
import { OpenModalSaveFilterAction } from '../+state/app-state/actions/open-modal-save-filter.action';
import { OpenModalSavedFiltersAction } from '../+state/app-state/actions/open-modal-saved-filters.action';
import { RemoveFilterFailureAction } from '../+state/app-state/actions/remove-filter-failure.action';
import { RemoveFilterSuccessAction } from '../+state/app-state/actions/remove-filter-success.action';
import { RemoveFilterAction } from '../+state/app-state/actions/remove-filter.action';
import { SaveFilterFailureAction } from '../+state/app-state/actions/save-filter-failure.action';
import { SaveFilterSuccessAction } from '../+state/app-state/actions/save-filter-success.action';
import { SaveFilterAction } from '../+state/app-state/actions/save-filter.action';
import { SetJobsFiltersLoadingStatus } from '../+state/app-state/actions/set-jobs-filters-loading-status';
import { SavedFilterInterface } from '../interfaces/saved-filter.interface';
import { SavedFiltersApiService } from './saved-filters.api.service';
import { SavedFiltersModalService } from './saved-filters.modal.service';

@Injectable()
export class SavedFiltersEffectsService {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly storeWrapper: StoreWrapperInterface,
        private readonly actions$: Actions,
        private readonly savedFiltersApi: SavedFiltersApiService,
        private readonly savedFiltersModal: SavedFiltersModalService,
    ) { }

    public init$(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionSuccessful(RemoveFilterAction),
                    mergeMap(({ filterId }) => this.savedFiltersApi.removeFilter$(filterId)),
                    tap(response => {
                        this.storeWrapper.showToast(response.message);

                        if (response.status === 'ok') {
                            this.removeFilterSuccess(response.data);

                            return;
                        }

                        this.removeFilterFailure();
                    }),
                    catchError(() => {
                        this.storeWrapper.showToast('Network or server error.');
                        this.removeFilterFailure();

                        return of(null);
                    }),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(SaveFilterAction),
                    mergeMap(({ filter }) => this.savedFiltersApi.saveFilter$(filter)),
                    tap(response => {
                        this.storeWrapper.showToast(response.message);

                        if (response.status === 'ok') {
                            this.saveFilterSuccess(response.data);

                            return;
                        }

                        this.saveFilterFailure();
                    }),
                    catchError(() => {
                        this.storeWrapper.showToast('Network or server error.');
                        this.saveFilterFailure();

                        return of(null);
                    }),
                ),
            this.actions$
                .pipe(
                    ofActionSuccessful(LoadFiltersAction),
                    tap(() => this.setJobsFiltersLoadingStatus()),
                    mergeMap(() => this.savedFiltersApi.getSavedFilters$()),
                    tap(response => {
                        this.storeWrapper.showToast(response.message);

                        if (response.status === 'ok') {
                            this.loadFiltersSuccess(response.data);

                            return;
                        }

                        this.loadFiltersFailure();
                        this.clearJobsFiltersLoadingStatus();
                    }),
                    catchError(() => {
                        this.storeWrapper.showToast('Network or server error.');
                        this.loadFiltersFailure();
                        this.clearJobsFiltersLoadingStatus();

                        return of(null);
                    }),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(OpenModalSaveFilterAction),
                    tap(({ filter }) => this.savedFiltersModal.openSaveFilterModal(filter)),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(OpenModalSavedFiltersAction),
                    tap(() => this.savedFiltersModal.openSavedFiltersModal()),
                ),
        );
    }

    @Dispatch()
    private removeFilterSuccess(payload: unknown): RemoveFilterSuccessAction {
        return new RemoveFilterSuccessAction(payload);
    }

    @Dispatch()
    private removeFilterFailure(): RemoveFilterFailureAction {
        return new RemoveFilterFailureAction();
    }

    @Dispatch()
    private loadFiltersSuccess(payload: Array<SavedFilterInterface>): LoadFiltersSuccessAction {
        return new LoadFiltersSuccessAction(payload);
    }

    @Dispatch()
    private loadFiltersFailure(): LoadFiltersFailureAction {
        return new LoadFiltersFailureAction();
    }

    @Dispatch()
    private saveFilterSuccess(payload: SavedFilterInterface): SaveFilterSuccessAction {
        return new SaveFilterSuccessAction(payload);
    }

    @Dispatch()
    private saveFilterFailure(): SaveFilterFailureAction {
        return new SaveFilterFailureAction();
    }

    @Dispatch()
    private setJobsFiltersLoadingStatus(): SetJobsFiltersLoadingStatus {
        return new SetJobsFiltersLoadingStatus();
    }

    @Dispatch()
    private clearJobsFiltersLoadingStatus(): ClearJobsFiltersLoadingStatus {
        return new ClearJobsFiltersLoadingStatus();
    }
}
